<!-- =========================================================================================
  File Name: projects.vue
  Description: All Projects list
  ----------------------------------------------------------------------------------------
  Item Name: KOZO Admin - VueJS Dashboard 
  Author:  
  Author URL:  
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <!-- TOP FILTER NAVBAR-->
      <div class="vx-col w-full">
        <div class="filter-nav">
          <div class="flex flex-wrap justify-between items-center">
            <h3 class="mb-0 font-bold ml-3">PROJECT LIST</h3>
            <div class="flex flex-wrap justify-end items-center">
              <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
              <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
                <vs-input
                  class="mb-4 md:mb-0 mr-4"
                  v-model="searchQuery"
                  @input="updateSearchQuery"
                  placeholder="Search..."
                />
                <!-- <ul class="list-inline">
                  <li
                    v-on:click="viewType('gridView')"
                    class="list-inline-item font-semibold cursor-pointer"
                  >
                    <feather-icon icon="GridIcon" svgClasses="h-7 w-7" />
                  </li>
                  <li
                    v-on:click="viewType('listView')"
                    class="list-inline-item font-semibold cursor-pointer"
                  >
                    <feather-icon icon="ListIcon" svgClasses="h-7 w-7" />
                  </li>
                </ul>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <verification-cards
        :items="[1,2,3,4,5,6,7,8,9]"
        :gridView="gridView"
        :listView="listView"
        :imagePath="require('@/assets/images/pages/search-result.jpg')"
      ></verification-cards>
    </div>
    <!-- <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" /> -->
  </div>
</template>

<script>
import ProjectCard from "@/components/project-cards/projectCard.vue";
import projectList from "@/components/movie-cards/projectList.vue";

import verificationCards from "@/views/apps/UserVerificationPage/verificationCards.vue";

export default {
  data() {
    return {
      gridView: true,
      listView: false,
      isActive: false,
      analyticsData: [],
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,

      users: []
    };
  },
  methods: {},
  components: {
    ProjectCard,
    projectList,
    verificationCards
  }
};
</script>

<style lang="scss">
</style>
