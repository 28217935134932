var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.GridView)?_c('div',{staticClass:"vx-row mt-10 bn_projectCard"},_vm._l((_vm.visibleProjects),function(project,projectIndex){return _c('div',{key:projectIndex,staticClass:"vx-col w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mb-base evenSpace"},[_c('div',{staticClass:"grid-view"},[_c('vx-card',{staticClass:"overflow-hidden project-cards"},[_c('div',{staticClass:"box d-flex align-items-center justify-content-between flex-column"},[_c('div',{staticClass:"mb-3 project-image"},[_c('img',{attrs:{"src":project.project_icon + '?token=' + _vm.tempToken,"alt":""}})]),_c('div',{staticClass:"project-info px-3"},[_c('h5',{staticClass:"font-weight-bold truncate"},[_vm._v("\n                "+_vm._s(project.project_name)+"\n              ")]),_c('p',{staticClass:"line-clamp"},[_vm._v(_vm._s(project.project_details))])]),_c('div',{staticClass:"action-icons px-4 pb-3"},[_c('ul',{staticClass:"list-inline text-right mt-4"},[(
                    project.editUserType == 0 || project.editUserType == 2 ||  _vm.permissionPageAccessArry.includes(
                      _vm.PermissionVariables.ViewOrganizationProjects
                    ) ||    _vm.loginUserRole == 'Admin' 
                  )?_c('li',{staticClass:"list-inline-item font-semibold"},[_c('router-link',{attrs:{"to":{
                      name: 'projectPosList',
                      params: { projectID: project.project_id }
                    }}},[_c('feather-icon',{attrs:{"icon":"ListIcon","svgClasses":"h-7 w-7"}})],1)],1):_vm._e(),(
                    (_vm.permissionPageAccessArry.includes(
                      _vm.PermissionVariables.projectEdit
                    ) ||
                      _vm.permissionPageAccessArry.includes(
                        _vm.PermissionVariables.viewEditSection
                      )) &&
                    _vm.permissionPageAccessArry.includes(
                      _vm.PermissionVariables.ViewOrganizationProjects
                    )
                      ? (_vm.permissionPageAccessArry.includes(
                          _vm.PermissionVariables.projectEdit
                        ) ||
                          _vm.permissionPageAccessArry.includes(
                            _vm.PermissionVariables.viewEditSection
                          )) &&
                        _vm.permissionPageAccessArry.includes(
                          _vm.PermissionVariables.ViewOrganizationProjects
                        )
                      : _vm.permissionPageAccessArry.includes(
                          '36P' + project.project_id
                        ) ||
                        _vm.loginUserRole == 'Admin' ||
                        _vm.permissionPageAccessArry.includes(
                          _vm.PermissionVariables.viewEditSection
                        )
                  )?_c('li',{staticClass:"list-inline-item font-semibold"},[_c('router-link',{attrs:{"to":{
                      name: 'editProjectView',
                      params: { projectID: project.project_id }
                    }}},[_c('feather-icon',{attrs:{"icon":"EditIcon","svgClasses":"h-7 w-7"}})],1)],1):_vm._e(),(
                    _vm.permissionPageAccessArry.includes(
                      _vm.PermissionVariables.projectArchive
                    ) &&
                    _vm.permissionPageAccessArry.includes(
                      _vm.PermissionVariables.ViewOrganizationProjects
                    )
                      ? _vm.permissionPageAccessArry.includes(
                          _vm.PermissionVariables.projectArchive
                        ) &&
                        _vm.permissionPageAccessArry.includes(
                          _vm.PermissionVariables.ViewOrganizationProjects
                        )
                      : _vm.permissionPageAccessArry.includes(
                          '37P' + project.project_id
                        ) || _vm.loginUserRole == 'Admin'
                  )?_c('li',{staticClass:"list-inline-item font-semibold"},[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.onClickArchive(project)}}},[_c('feather-icon',{attrs:{"icon":"ArchiveIcon","svgClasses":"h-7 w-7"}})],1)],1):_vm._e(),(
                    _vm.permissionPageAccessArry.includes(
                      _vm.PermissionVariables.projectDelete
                    ) &&
                    _vm.permissionPageAccessArry.includes(
                      _vm.PermissionVariables.ViewOrganizationProjects
                    )
                      ? _vm.permissionPageAccessArry.includes(
                          _vm.PermissionVariables.projectDelete
                        ) &&
                        _vm.permissionPageAccessArry.includes(
                          _vm.PermissionVariables.ViewOrganizationProjects
                        )
                      : _vm.permissionPageAccessArry.includes(
                          '38P' + project.project_id
                        ) || _vm.loginUserRole == 'Admin'
                  )?_c('li',{staticClass:"list-inline-item font-semibold"},[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.projectDelete(project)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon","svgClasses":"h-7 w-7"}})],1)],1):_vm._e()])])])])],1)])}),0):_vm._e(),(_vm.ListView)?_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full mb-base"},[_c('div',{staticClass:"list-view",attrs:{"id":"data-list-list-view"}},[_c('vs-table',{ref:"table",attrs:{"pagination":"","max-items":4,"data":_vm.project},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var projects = ref.projects;
return [_c('tbody',_vm._l((projects),function(project,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":project}},[_c('vs-td',[_c('span',[_vm._v(_vm._s(indextr + 1))])]),_c('vs-td',[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(project.project_name))]),_c('p',[_vm._v(_vm._s(project.project_details))])]),_c('vs-td',[_c('router-link',{attrs:{"to":{
                      name: 'projectPosList',
                      params: { projectID: project.project_id }
                    }}},[_c('feather-icon',{attrs:{"icon":"ListIcon","svgClasses":"h-7 w-7"}})],1)],1),_c('vs-td',[_c('router-link',{attrs:{"to":{
                      path: '/editProjectView',
                      params: { projectID: project.project_id }
                    }}},[_c('feather-icon',{attrs:{"icon":"EditIcon","svgClasses":"h-7 w-7"}})],1)],1),_c('vs-td',[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.getAllArchiveProjects()}}},[_c('feather-icon',{attrs:{"icon":"ArchiveIcon","svgClasses":"h-7 w-7"}})],1)],1)],1)}),1)]}}],null,false,3178060546)})],1)])]):_vm._e(),_c('div',{staticClass:"demo-alignment"},[_c('vs-popup',{staticClass:"holamundo p-2",attrs:{"id":'popmodalS',"button-close-hidden":true,"title":"Delete Project","active":_vm.popupActive},on:{"update:active":function($event){_vm.popupActive=$event}}},[_c('div',{staticClass:"text-cent er col-12"},[_c('h5',[_vm._v("\n          Do you want to delete "+_vm._s(_vm.project_detail.project_name)+" project ?\n        ")]),_c('div',{staticClass:"vx-row mt-5"},[_c('div',{staticClass:"vx-col mx-auto"},[_c('vs-button',{staticClass:"mr-3",attrs:{"color":"success","type":"filled"},on:{"click":function($event){return _vm.deleteProjectNew()}}},[_vm._v("Yes")]),_c('vs-button',{attrs:{"color":"danger","type":"filled"},on:{"click":function($event){return _vm.closeModalDelete()}}},[_vm._v("No")])],1)])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }